import React from 'react'
import sectionText from '../Helpers/RowText'
import Button from '../Components/Button'
import scrollTo from '../Helpers/scrollTo'
import '../styles/4-views/_row.scss'

function Row(props) {

    return (
        <section className={"row"}>
            <div className="row__wrapper">
                <div className={`row__content --${props.section}-content`}>
                    <div className={`row__text-box --${props.section}-text-box`}>
                        <h1 className={`row__text --${props.section}-text .--text-active`}>{sectionText(props.section).text1}</h1>
                        <h1 className={`row__text --${props.section}-text .--text-active`}>{sectionText(props.section).text2}</h1>
                    </div>
                    {props.section === "cta"
                        && <div className="button-box">
                            <Button
                                variant="contained"
                                color="primary"
                                className="cta-button"
                            >
                                <a href="#contact" onClick={e => scrollTo(e)}>click aquí</a>
                            </Button>
                        </div>
                    }
                </div>
            </div>
        </section >
    )
}

export default Row
