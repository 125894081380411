export default function LinkRef(value) {
  switch (value) {
    case "inicio":
      return "#home";
    case "asesoramiento":
      return "#expertise";
    case "contacto":
      return "#contact";
    case "conocenos":
      return "#about-us";
    default:
      break;
  }
}
