import expImg1 from "../Assets/Illustrations/Expertise/family.svg";
import expImg2 from "../Assets/Illustrations/Expertise/court.svg";
import expImg3 from "../Assets/Illustrations/Expertise/liquidation-services.svg";
import expImg4 from "../Assets/Illustrations/Expertise/litigation-negotiation.svg";
import expImg5 from "../Assets/Illustrations/Expertise/corporate-law.svg";
import expImg6 from "../Assets/Illustrations/Expertise/labor-law.svg";

export const expertises = [
  {
    title: "familia",
    text: "divorcios, cuota alimentaria, regimen de visitas.",
    img: expImg1,
  },
  {
    title: "sucesiones",
    text: "testamentos, declaratoria de herederos.",
    img: expImg2,
  },
  {
    title: "laboral",
    text: "jubilaciones, despidos, accidentes, ART, suspensiones.",
    img: expImg3,
  },
  {
    title: "contratos",
    text: "alquileres, compra/venta, locacion de servicios, entre otros.",
    img: expImg4,
  },
  {
    title: "accidentes",
    text: "laborales, transito.",
    img: expImg5,
  },
  {
    title: "quiebras",
    text: "concurso preventivo, concurso de quiebras, cesación de pagos.",
    img: expImg6,
  },
];
