import React, { useState, Fragment } from 'react'
import Drawer from '../../Components/Drawer'
import { Button } from '@material-ui/core'
import MenuBars from '@material-ui/icons/Menu'
import MenuX from '@material-ui/icons/Close'
import NavBar from '../../Components/NavBar'
import Logo from '../../Assets/Images/logo.png'
import useWindowDimensions from '../../Hooks/UseWindowDimensions'
import '../../styles/3-layout/home/_nav.scss'


function Nav(props) {

    const { width } = useWindowDimensions();

    const [toggle, setToggle] = useState(false)

    function handleClick(e) {
        setToggle(!toggle)
        props.onClick(e)
    }

    function handleScroll() {
        window.scrollTo(0, 0)
    }

    return (
        <nav className="nav">
            <div className="nav__wrapper">
                <div
                    className="nav__brand"
                    onClick={() => handleScroll()}
                >
                    <div className="nav__logo-box">
                        <img
                            src={Logo}
                            alt="logo"
                            loading="lazy"
                        />
                    </div>
                    <div className="nav__company-name">
                        <h1 className="company__name"> <span>cinzano</span> estudio jurídico</h1>
                    </div>
                </div>
                {width < 1024
                    ? <Fragment>
                        <Button
                            aria-label="menu button"
                            onClick={() => handleClick()}
                            id="menu-button"
                        >
                            {toggle
                                ? <MenuX className="nav__menu-button" />
                                : <MenuBars className="nav__menu-button" />
                            }
                        </Button>
                        <Drawer
                            open={toggle}
                            onClick={e => handleClick(e)}
                        />
                    </Fragment>
                    : <Fragment>
                        <NavBar
                            onClick={e => handleClick(e)}
                        />
                        {toggle && setToggle(false)}
                    </Fragment>
                }
            </div>
        </nav>
    )
}

export default Nav
