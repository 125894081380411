import React, { useState } from 'react'
import Home from '../Views/Home'
import Row from '../Views/Row'
import Expertise from '../Views/Expertise'
import Contact from '../Views/Contact'
import Footer from '../Views/Footer'
import { Grid } from '@material-ui/core'
import { useInView } from 'react-intersection-observer';
import { InView } from 'react-intersection-observer';
import useWindowDimensions from '../Hooks/UseWindowDimensions'
import { sendEmail } from '../Helpers/SendEmail'
import '../styles/5-Pages/_homePage.scss'
import Modal from '../Components/Modal'

function HomePage() {

  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });
  const { width } = useWindowDimensions()

  /*Modal */
  const [showModal, setShowModal] = useState(false)
  const [msg, setMsg] = useState('msgLoading')

  async function handleEmail(e) {
    const emailState = await sendEmail(e)
    if (emailState && emailState.text === 'OK') {
      setMsg('msgOk')
      e.target.reset()
    } else if (emailState) {
      setMsg('msgError')
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    setShowModal(true)
    handleEmail(e)
  }

  function handleClose(e) {
    e.preventDefault()
    setShowModal(prev => !prev)
    setMsg('msgLoading')
  }
  /*Modal */

  return (
    <div className="Home-page">
      <Home />
      <Grid
        container
        className="cta-quote__grid-container"
        ref={ref}
      >
        <Grid
          item
          x={12}
          lg={4}
          xl={3}
          className={
            width > 1280 && inView
              ? "cta-quote__grid-item --active-from-left"
              : "cta-quote__grid-item"
          }
        >
          <Row section="cta" />
        </Grid>
        <Grid
          item
          xs={12}
          lg={7}
          xl={6}
          className={
            width > 1280 && inView
              ? "cta-quote__grid-item --active-from-right"
              : "cta-quote__grid-item"
          }
        >
          <Row section="quote" />
        </Grid>
      </Grid>
      <Expertise />
      <InView triggerOnce={true}>
        {({ inView, ref }) => (
          <Grid
            container
            className="contact__grid-container"
            ref={ref}
          >
            <Grid
              item
              xs={12}
              lg={4}
              xl={3}
              className={
                width > 1280 && inView
                  ? "contact__grid-item --active-from-left"
                  : "contact__grid-item"
              }
            >
              <Row section="online-counseling" />
            </Grid>
            <Grid
              item
              xs={12}
              lg={7}
              xl={6}
              className={
                width > 1280 && inView
                  ? "contact__grid-item --active-from-right"
                  : "contact__grid-item"
              }
            >
              <Contact onSubmit={e => handleSubmit(e)} />
            </Grid>
            {showModal &&
              <Modal
                showModal={showModal}
                msg={msg}
                onClick={e => handleClose(e)}
              />
            }
          </Grid>
        )}
      </InView>
      <Footer />
    </div>
  );
}

export default HomePage;
