import React, { useState } from 'react'
import AboutUsImg from '../Assets/Images/AboutUs/aboutUs.jpg'
import AboutUsImg2 from '../Assets/Images/AboutUs/aboutUs2.jpg'
import Title from '../Components/Title'
import { useInView } from 'react-intersection-observer'
import '../styles/4-views/_aboutUs.scss'

function AboutUs(props) {

    const { ref, inView } = useInView({ threshold: 0.6, triggerOnce: true })

    const [back, setBack] = useState(false)

    function handleClick() {
        setBack(true)
        props.onClick()
    }

    return (
        <section className="about-us" id="about-us" ref={ref}>
            <div className={
                back
                    ? "page-effect --anim-back-home"
                    : "page-effect"
            }
            >
                <div className="page-effect__trigger" onClick={() => handleClick()}></div>
            </div>
            <div className={
                back
                    ? "about-us__wrapper --opacity"
                    : "about-us__wrapper"
            }
            >
                <div className="about-us__img-box">
                    <img
                        src={AboutUsImg}
                        srcSet={
                            `${AboutUsImg} 300w,
                            ${AboutUsImg2} 400w`
                        }
                        alt="perfil abogada Lusina Cinzano"
                        className="about-us__img"
                    />
                </div>
                <div className="about-us__text-box">
                    <Title
                        section="about-us"
                        inView={inView}
                    />
                    <p className="about-us__text --active-from-top-bottom">
                        Mi nombre es Luisina Cinzano, soy abogada egresada de la Universidad Nacional de Rosario.
                        Actualmente me encuentro matriculada en el colegio de abogados de San Nicolás de los Arroyos, Bs.As., matrícula tomo XIV folio 52.
                        Inicie el estudio hace algunos años con la fuerte convicción de hacer valer los derechos de mis representados y derribar la idea de que el derecho es para unos pocos.
                        En pos de brindar lo mejor para nuestros clientes abrimos ésta nueva via de comunicación.
                        Nos ponemos a disposición de manera rápida via online ante cualquier consulta que requieran.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default AboutUs