import slide1 from "../Assets/Images/Slider/slide1.png";
import slide2 from "../Assets/Images/Slider/slide2.jpg";
import slide3 from "../Assets/Images/Slider/slide3.jpg";
import slide4 from "../Assets/Images/Slider/slide4.jpg";
import slide11 from "../Assets/Images/Slider/slide11.png";
import slide22 from "../Assets/Images/Slider/slide22.jpg";
import slide33 from "../Assets/Images/Slider/slide33.jpg";
import slide44 from "../Assets/Images/Slider/slide44.jpg";

const carouselContent = [
  { 
    slide: slide1, 
    slide2: slide11, 
    text: null, 
    text2: null,
    wSlide: '375w',
    wSlide2: '750w'
  },
  {
    slide: slide2,
    slide2: slide22,
    text: "Nueva ley de alquileres. Inquilino o propietario, asesorate.",
    text2: null,
    wSlide: '640w',
    wSlide2: '1280w'
  },
  {
    slide: slide3,
    slide2: slide33,
    text: "Nuestros hijos siempre están primero.",
    text2: "Conocé como cuidarlos.",
    wSlide: '640w',
    wSlide2: '1280w'
  },
  {
    slide: slide4,
    slide2: slide44,
    text: "¿Despidos sin causa o en pandemia?",
    text2: "Tus derechos valen.",
    wSlide: '640w',
    wSlide2: '1280w'
  },
];

export default carouselContent;
