import React from 'react'
import '../styles/2-components/_loadingSpinner.scss'
import loadingSpinner from '../Assets/Images/Spinner/loadingSpinner.png'
import loadingSpinner2 from '../Assets/Images/Spinner/loadingSpinner2.png'

export default function LoadingSpinner() {

    return (
        <div className="loading-spinner">
            <div className="loading-spinner__wrapper">
                <img
                    src={loadingSpinner2}
                    srcSet={
                        `${loadingSpinner2} 585w, 
                        ${loadingSpinner} 400w`
                    }
                    alt="justice scale"
                    className="loading-spinner__img"
                />
            </div>
        </div>
    )
}
