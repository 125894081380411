import React from 'react'
import AboutUs from '../Views/AboutUs'

function AboutUsPage(props) {
    return (
        <div className="about-us-page">
            <AboutUs onClick={()=>props.onClick()}/>
        </div>
    )
}

export default AboutUsPage