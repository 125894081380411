import React from 'react'
import Title from '../Components/Title'
import ExpertiseItem from '../Components/ExpertiseItem'
import { Grid, Box } from '@material-ui/core'
import { expertises } from '../Helpers/Expertise'
import { useInView } from 'react-intersection-observer'
import '../styles/4-views/_expertise.scss'

export default function Expertise() {

    const { ref, inView } = useInView({ threshold: 0.2, triggerOnce: true });

    return (
        <section
            className="expertise"
            id="expertise"
            ref={ref}
        >
            <div className="expertise__wrapper">
                <Title
                    section="expertise"
                    inView={inView}
                />
                <div className="expertise__items-wrapper">
                    <Grid
                        container
                        className={
                            inView
                                ? "expertise__grid-container --rotate-tiles"
                                : "expertise__grid-container"
                        }
                    >
                        {expertises.map((expertise, index) =>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                key={index}
                                className="expertise__grid-item"
                            >
                                <Box p={3}>
                                    <ExpertiseItem
                                        expertise={expertise}
                                        inView={inView}
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </div>
        </section >
    )
}
