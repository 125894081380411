export default function TitleText(section) {
  switch (section) {
    case "expertise":
        return "asesoramiento integral"
    case "contact":
        return "formulario de contacto"
    case "about-us":
        return "cinzano | estudio juridico"
    default:
      break;
  }
}
