export default function rowText(section) {
  switch (section) {
    case "cta":
      return {
        text1: "¿Necesitas asesoramiento legal?",
        text2: "Accedé a una consulta.",
      };
    case "quote":
      return {
        text1:
          "El  deber del abogado es luchar por el derecho, pero el día que se encuentre en conflicto el derecho con la justicia, su deber es luchar por la justicia...",
      };
    case "online-counseling":
      return {
        text1: "Anticipate a los problemas,",
        text2: "podes gestionar una consulta rápida online.",
      };
    default:
      break;
  }
}
