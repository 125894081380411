import React from 'react'
import LinkRef from '../Helpers/LinkRef'
import scrollTo from '../Helpers/scrollTo'
import '../styles/2-components/_footerLink.scss'

export default function FooterLink(props) {

    return (
        <div
            key={props.index}
            className={
                props.link
                    ? "footer-links__link"
                    : "footer-links__link --icon-link"
            }
        >
            <a
                href={
                    props.link
                        ? LinkRef(props.link)
                        : props.socialLink
                }
                onClick={e => scrollTo(e)}
            >
                {
                    props.link
                        ? props.link
                        : props.shortLink
                }
            </a>
        </div>
    )
}
