import React from 'react'
import LinkRef from '../Helpers/LinkRef'
import useWindowDimensions from '../Hooks/UseWindowDimensions'
import scrollTo from '../Helpers/scrollTo'

export default function NavLink(props) {

    const { width } = useWindowDimensions()
    

    function handleClick(e) {
        scrollTo(e)
        props.onClick(e)
    }

    return (
        <div
            className={
                width < 1024
                    ? "nav__drawer-item-box"
                    : "nav__bar-item-box"
            }
        >
            <a
                href={LinkRef(props.item)}
                className={
                    width < 1024
                        ? "nav__drawer-item"
                        : "nav__bar-item"
                }
                onClick={e => handleClick(e)}
            >
                {props.item}
            </a>
        </div>
    )
}
