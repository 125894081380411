import Whatsapp from '../Assets/Icons/Footer/whatsapp.svg'
import Instagram from '../Assets/Icons/Footer/instagram.svg'
import Mail from '../Assets/Icons/Footer/gmail.svg'

export const socials = [
  {
    iconImg: Whatsapp,
    link: "https://api.whatsapp.com/send/?phone=5493364202703&text&app_absent=0",
    shortLink: "+5493364202703",
  },
  {
    iconImg: Instagram,
    link: "https://www.instagram.com/cinzano.estudiojuridico/?hl=es-la",
    shortLink: "@cinzano.estudiojuridico",
  },
  {
    iconImg: Mail,
    link: "mailto:recepcion@cinzanoestudiojuridico.com.ar?subject=Consulta%20desde%20sitio%20web&body=Escriba%20aquí%20tu%20consulta...%0A%0APuede%20adjuntar%20archivos%20si%20lo%20desea.%20",
    shortLink: "recepcion@cinzanoestudiojuridico.com.ar",
  },
];


export const links = ["inicio", "asesoramiento", "contacto"];
