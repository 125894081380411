import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import NavLink from './NavLink'
import { items } from '../Helpers/NavLinks'
import '../styles/2-components/_drawer.scss'

export default function Drawer(props) {

    const [showDrawer, setShowDrawer] = useState(false)

    useEffect(() => setShowDrawer(props.open), [props.open])

    function handleClick(e) {
        document.querySelector('.nav__drawer--overlay').className += " --transition-close--left"
        document.querySelector('.nav__drawer-wrapper').className += " --transition-close--right"
        setTimeout(() => props.onClick(e), 100)
    }

    return (
        showDrawer &&
        <div className="nav__drawer" >
            <div
                className="nav__drawer--overlay active-drawer"
                name="overlay"
                onClick={e => handleClick(e)}
            ></div>
            <div className="nav__drawer-wrapper active-drawer-content">
                <div className="nav__drawer-items">
                    <Grid container spacing={3}>
                        {
                            items.map((item, index) =>
                                <Grid item xs={12} key={index}>
                                    <NavLink
                                        item={item}
                                        onClick={e => props.onClick(e)}
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
                </div>
            </div>
        </div>
    )
}
