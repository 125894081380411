import React from 'react'
import '../styles/2-components/_expertiseItem.scss';

export default function ExpertiseItem(props) {

    return (
        <div
            className="expertise__item"
            key={props.index}
        >
            <div className="expertise__item-img-box">
                <img
                    src={props.expertise.img}
                    alt="expertise-img"
                    loading="lazy"
                />
            </div>
            <div className="expertise__item-text-box">
                <div className="expertise__item-title-box">
                    <h1 className="expertise__item-title">{props.expertise.title}</h1>
                </div>
                <div className="expertise__item-description-box">
                    <p className="expertise__item-description">{props.expertise.text}</p>
                </div>
            </div>
        </div>
    )

}