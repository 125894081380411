import React, { useState, useEffect, Fragment } from 'react'
import HomePage from './Pages/HomePage'
import AboutUsPage from './Pages/AboutUsPage'
import LoadingSpinner from './Components/LoadingSpinner'
import Nav from './Layout/Home/Nav'
import scrollTo from './Helpers/scrollTo'

function App() {

  const [loadingSpinner, setLoadingSpinner] = useState(true)
  const [showAboutUs, setShowAboutUs] = useState(false)

  function handlePages(e) {
    if (e === undefined) {
      return
    } else if (e && e.target.href !== undefined && (e.target.href).substr(-8) === "about-us") {
      setShowAboutUs(true)
    } else if (e && e.target.classList[0] === "nav__drawer--overlay") {
      return
    } else {
      setShowAboutUs(false)
    }
  }

  useEffect(() => {
    setTimeout(() => setLoadingSpinner(false), 1500)
  }, [])

  function handleBackHomeClick() {
    setTimeout(() => setShowAboutUs(false), 500)
  }

  return (
    <div className="App">
      {loadingSpinner
        ? <LoadingSpinner />
        : <Fragment>
          <Nav onClick={e => handlePages(e)} />
          {showAboutUs
            ? <AboutUsPage onClick={() => handleBackHomeClick()} />
            : <HomePage />
          }
        </Fragment>
      }
    </div>
  );
}

export default App;
