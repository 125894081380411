import React from 'react'
import FooterLink from '../Components/FooterLink'
import { socials, links } from '../Helpers/FooterLinks'
import { Grid } from '@material-ui/core'
import useWindowDimensions from '../Hooks/UseWindowDimensions'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Icon from '../Components/Icon'
import '../styles/4-views/_footer.scss'


export default function Footer() {


    const { width } = useWindowDimensions()

    return (
        <footer className="footer">
            <div className="footer__wrapper">
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                    >
                        <div className="footer__arrow-box">
                            <a href="#home" alt="arrow-button"><ChevronLeft /></a>
                            <hr />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <div className="footer-socials">
                            <div className="footer-socials__title-box">
                                <h1 className="footer-socials__title">canales de comunicación</h1>
                            </div>
                            <div className="footer-socials__icons-wrapper">
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    {socials.map((icon, index) =>
                                        <Grid
                                            item
                                            xs={4}
                                            md={12}
                                            key={index}
                                        >
                                            <Icon icon={icon} />
                                            {width > 960
                                                && <FooterLink
                                                    socialLink={icon.link}
                                                    shortLink={icon.shortLink}
                                                />
                                            }
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                    >
                        <div className="footer-links">
                            <div className="footer-links__title-box">
                                <h4 className="footer-links__title">links</h4>
                            </div>
                            <div className="footer-links__wrapper">
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    {links.map((link, index) =>
                                        <Grid
                                            item
                                            xs={12}
                                            key={index}
                                        >
                                            <FooterLink
                                                link={link}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="copyright__box">
                                <p className="copyright__text">
                                    copyright &#169; 2021
                                    <span> | </span>
                                    <a href="mailto:romanluporini@gmail.com?subject=Consulta%20desde%20sitio%20web&body=Escriba%20aquí%20tu%20consulta...%0A%0APuede%20adjuntar%20archivos%20si%20lo%20desea.%20">
                                        Román Luporini
                                    </a>
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </footer>
    )
}

