var prevScrollId;

export default async function scrollTo(e) {
  if (prevScrollId === "#about-us") {
    prevScrollId = "";
    setTimeout(() => {window.scrollBy(0, -75)}, 1000);
    return;
  }
  let elemRef = e.target.href;
  let strArr = elemRef.split("/");
  let elemId = [...strArr].pop();
  prevScrollId = elemId;
  if (elemId !== "#about-us" && document.querySelector(elemId)) {
    e.preventDefault();
    let sh =
      window.scrollY +
      document.querySelector(elemId).getBoundingClientRect().top;
    window.scroll(0, sh - 75);
  }
}
