import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import '../styles/2-components/_ctaButton.scss'

// We can inject some CSS into the DOM.
const styles = {
    root: {
        textTransform: 'capitalize',
        background: '#235AB9',
        border: 0,
        borderRadius: 40,
        color: 'white',
        height: 36,
        width:150,
        padding: '0 24px',
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    },
};

function ClassNames(props) {
    const { classes, children, className, ...other } = props;

    return (
        <Button className={clsx(classes.root, className)} {...other}>
            {children || 'class names'}
        </Button>
    );
}

ClassNames.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withStyles(styles)(ClassNames);