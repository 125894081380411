import React from 'react'
import Title from '../Components/Title'
import ContactForm from '../Layout/Contact/ContactForm'
import { useInView } from 'react-intersection-observer'
import '../styles/4-views/_contact.scss'

export default function Contact(props) {

    const { ref, inView } = useInView({ threshold: 0.6, triggerOnce: true })

    return (
        <section
            className="contact"
            id="contact"
            ref={ref}
        >
            <div className="contact__wrapper">
                <Title
                    section="contact"
                    inView={inView}
                />
                <ContactForm inView={inView} onSubmit={e => props.onSubmit(e)} />
            </div>
        </section>
    )
}
