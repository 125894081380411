import React from 'react'
import Button from '../../Components/Button'
import { Grid, TextareaAutosize } from '@material-ui/core'

export default function ContactForm(props) {

    return (
        <form
            className="contact__form"
            onSubmit={e => props.onSubmit(e)}
        >
            <div className="contact__form-inputs">
                <Grid
                    container
                    spacing={2}
                    className="contact__grid-container"
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={
                            props.inView
                                ? "contact__inner-grid-item --active-from-top-bottom"
                                : "contact__inner-grid-item"
                        }
                    >
                        <div className="input-box">
                            <input
                                type="text"
                                name="from_name"
                                required
                            />
                            <label>Nombre y Apellido</label>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={
                            props.inView
                                ? "contact__inner-grid-item --active-from-top-bottom"
                                : "contact__inner-grid-item"
                        }
                    >
                        <div className="input-box">
                            <input
                                type="email"
                                name="reply_to"
                                required
                            />
                            <label>E-mail</label>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={
                            props.inView
                                ? "contact__grid-item-msg --active-from-top-bottom"
                                : "contact__grid-item-msg"
                        }
                    >
                        <TextareaAutosize
                            name="message"
                            rows={10}
                            placeholder="Escribe tu mensaje aquí"
                            required
                            className={"contact__email-body"}
                        >
                        </TextareaAutosize>
                    </Grid>
                </Grid>
            </div>
            <Button
                variant="contained"
                color="primary"
                type="submit"
            >
                <a>enviar</a>
            </Button>
        </form>
    )
}
