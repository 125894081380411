import emailjs from "emailjs-com";

export function sendEmail(e) {
  return emailjs
    .sendForm(
      "service_hs2ed5c",
      "template_j00gadl",
      e.target,
      "user_LgsDeOqoNZlKIpQkHJm8W"
    )
    .then(
      result => {
        console.log(result.text);
        return result;
      },
      error => {
        console.log(error.text);
        return error;
      }
    );
}

